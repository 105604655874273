/**
 * 系统设置
 */

import request from '@/utils/request'

/**
 * 获取站点设置
 */
export function getSiteSetting() {
  return request({
    url: 'admin/settings/site',
    method: 'get',
    loading: false
  })
}

/**
 * 修改站点设置
 * @param params
 */
export function editSiteSetting(params) {
  return request({
    url: 'admin/settings/site',
    method: 'put',
    data: params
  })
}

/**
 * 获取积分设置
 */
export function getPointSetting() {
  return request({
    url: 'admin/settings/point',
    method: 'get'
  })
}

/**
 * 修改积分设置
 * @param params
 */
export function editPointSetting(params) {
  return request({
    url: 'admin/settings/point',
    method: 'put',
    data: params
  })
}

/**
 * 查询主动短信记录表列表，用于分页查询
 * @param params
 */
export function getMessageDiytemplate(params) {
  return request({
    url: '/admin/systems/messageDiytemplate/page',
    method: 'post',
    params
  })
}

/**
 * 添加主动短信记录表
 * @param params
 */
export function addMessageDiytemplate(params) {
  return request({
    url: '/admin/systems/messageDiytemplate/add',
    method: 'post',
    params,
    // log: {
    //   type: '3',
    //   name: '消息模板添加'
    // }
  })
}

/**
 * 修改主动短信记录表
 * @param params
 */
export function editMessageDiytemplate(params) {
  return request({
    url: '/admin/systems/messageDiytemplate/edit',
    method: 'post',
    params,
    // log: {
    //   type: '3',
    //   name: '消息模板修改'
    // }
  })
}

/**
 * 删除主动短信记录表
 * @param params
 */
export function deleteMessageDiytemplate(id) {
  return request({
    url: `/admin/systems/messageDiytemplate/${id}`,
    method: 'DELETE',
    // log: {
    //   type: '3',
    //   name: '消息模板删除'
    // }
  })
}
