<template>
  <div>
    <!-- activesms-template-list 自动消息模板 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <div class="conditions">
            <el-button class="btn-default" @click="handleAdd">添加</el-button>
          </div>

          <!--关键字-->
          <div class="conditions">
            <el-input
              class="ipt-default"
              style="width: 300px"
              v-model="advancedForm.keyword"
              placeholder="请输入模板名称/模板CODE进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="id" label="编号" />
        <el-table-column prop="tpl_name" label="模板名称" />
        <el-table-column prop="tpl_code" label="模板CODE" />
        <el-table-column prop="sms_code" label="阿里云编号" />
        <el-table-column prop="sms_content" label="模板内容" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!--操作 dialog-->
    <el-dialog
      :title="titleName+'模板'"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="editForm" :rules="MessageVisibleRules" ref="editForm" label-width="100px">
        <!--模板名称-->
        <el-form-item label="模板名称" prop="tpl_name">
          <el-input v-model="editForm.tpl_name"></el-input>
        </el-form-item>
        <!--模板CODE-->
        <el-form-item label="模板CODE" prop="tpl_code">
          <el-input v-model="editForm.tpl_code"></el-input>
        </el-form-item>
        <!--模板内容-->
        <el-form-item label="模板内容" prop="sms_content">
          <el-input type="textarea" v-model="editForm.sms_content"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_systemSetting from "@/api/systemSetting";
// import { RegExp } from "bms-common/ui-utils";

export default {
  name: "shopaccountList",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        keyword: "", // 根据关键字搜索
      },

      /** 编辑数据 */
      titleName: "添加",
      dialogVisible: false,
      editForm: {},
      MessageVisibleRules: {
        //  tpl_name tpl_code sms_content
        tpl_name: [
          { required: true, message: "请填写模板名称", trigger: "blur" },
        ],
        tpl_code: [
          { required: true, message: "请填写模板CODE", trigger: "blur" },
        ],
        sms_content: [
          { required: true, message: "请填写模板内容", trigger: "blur" },
        ],
        // mobile: [
        //   this.MixinRequired("请填写用户手机号"),
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!RegExp.mobile.test(value)) {
        //         callback(new Error("手机格式有误！"));
        //       } else {
        //         callback();
        //       }
        //     },
        //   },
        // ],
      },
    };
  },
  mounted() {
    this.GET_List();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_List();
    next();
  },
  methods: {
    handleAdd() {
      this.editForm = {};
      this.titleName = "添加";
      this.dialogVisible = true;
    },
    handleDelete(row) {
      this.$confirm(`确认要删除该短信模板吗？`, "提示", { type: "warning" })
        .then(() => {
          console.log("删除");
          API_systemSetting.deleteMessageDiytemplate(row.id).then(() => {
            this.$message.success("操作成功！");
            this.GET_List();
          });
        })
        .catch(() => {});
      console.log(row);
    },
    handleEdit(row) {
      // this.editForm = JSON.parse(JSON.stringify(row));
      this.editForm = this.MixinClone(row);
      this.titleName = "修改";
      this.dialogVisible = true;
    },

    /** 编辑保存 */
    submitForm() {
      let flag;
      this.titleName === "修改"
        ? (flag = "editMessageDiytemplate")
        : (flag = "addMessageDiytemplate");
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          console.log(this.editForm, "editFormeditForm");
          this.loading = true;
          const params = this.MixinClone(this.editForm);
          params.del_flag = 1; // 是否删除  1否  0 是
          API_systemSetting[flag](params)
            .then((res) => {
              this.loading = false;
              this.$message.success(`${this.titleName}成功！`);
              this.dialogVisible = false;
              this.GET_List();
            })
            .catch((_) => {
              this.loading = false;
            });
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      this.params.name = this.advancedForm.keyword;
      this.GET_List();
    },

    /** 获取会员列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_systemSetting.getMessageDiytemplate(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
